<template>
  <div style="height: 100%">
    <div class="screenWidth center" v-if="flag">
      <el-carousel :interval="interval" arrow="always" indicator-position="outside">
        <el-carousel-item v-for="(item, index) in imgList" :key="index">
          <img :src="item.mynameimg" alt="" width="100%">
        </el-carousel-item>
      </el-carousel>
    </div>
    <div id="app" :style="{'height': flag ? '1220px':'100%'}">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import {
  api_banner
} from '@/api/home';
export default {
  name: 'App',
  data() {
    return {
      onLine: navigator.onLine,
      flag: false,
      interval: 5000,
      imgList:[]
    };
  },
  mounted () {
    // window.googleBrower.checkShell("123")
    window.addEventListener('unload', this.saveState);
    window.addEventListener('online',  this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
    const screenHeight = window.screen.height;
    // const screenWidth = window.screen.width;
    // console.log(screenHeight,'----------screenHeight',screenWidth);
    if(screenHeight == 1920){
      this.flag = true;
    }
    this.getBanner()
  },
  methods: {
    saveState () {
      sessionStorage.setItem('state', JSON.stringify(this.$store.state))
    },
    updateOnlineStatus(e) {
      const { type } = e;
      this.onLine = type === 'online';
      localStorage.setItem('lineType', e.type)
      if(e.type === 'offline'){
        this.$router.push({name: "OffLine"});
      }
      // else{
      //   this.$router.push({name: "MyHome"});
      // }
    },
    getBanner() {
      const params = {
        groupcode: this.$store.state.user.groupcode
      }
      api_banner(params).then(res => {
        if (res.code == '0') {
          this.imgList = res.data
        }
      })
    },
 },
  // beforeDestroy(){
  //   window.removeEventListener('online',  this.updateOnlineStatus);
  //   window.removeEventListener('offline', this.updateOnlineStatus);
  // },
}
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  text-align: center;
  height: 100%;
  width: 100%;
  background: #d3d3d3 url("~@/assets/img/bj.png") no-repeat;
}
.el-carousel__container {
  position: relative;
  height: 700px !important;
  margin-bottom: 50px;
  text-align: center;
}
.el-carousel__container{
  margin-bottom: 0;
}

.el-carousel__indicators--outside {
  bottom: 26px;
  text-align: center;
  transform: none;
  position: absolute !important;
  left: 0 !important;
  right: 0 !important;
}
.center{
  margin: auto;
}
</style>

