<template>
  <div class="header header-box screenWidth">
    <span class="floatL">
      <img class="logo" :src="logoImg" alt="" :style="{'margin-left':flag? '20px': '0px'}">
      <!-- <img class="logo" :src="require('@/assets/img/1.png')" alt=""> -->
      <!-- <img class="logo" :src="require('@/assets/img/2.jpg')" alt=""> -->
      <!-- 苏演票务 -->
    </span>
    <span class="floatR" :style="{'margin-right':flag? '20px': '0px'}">{{time}}</span>
  </div>
</template>

<script>
export default {
  name: 'MyHeader',
  props: {
    msg: String
  },
  data() {
    return {
      time: '',
      screenWidth: '',
      flag: false
    }
  },
  mounted () {
    this.timer = setInterval(() => {    
    this.setNowTimes()  
    },1000)
    const screenWidth = window.screen.width;
    if(screenWidth <= 1280){
      this.flag = true;
    }
  },
  computed: {
    logoImg() {
      return this.$store.state.user.platformSettings.length && this.$store.state.user.platformSettings.find(el => el.name == 'XCXorH5homepageLogoURL').value
    }
  },
  methods: {
    setNowTimes(){
      var myDate = new Date();
      var month = myDate.getMonth() < 9 ? "0" + (myDate.getMonth() + 1) : myDate.getMonth() + 1;
      var date = myDate.getDate() <= 9 ? "0" + myDate.getDate() : myDate.getDate();
      var hou = myDate.getHours() < 10 ? '0' + myDate.getHours() : myDate.getHours();
      var min = myDate.getMinutes() < 10 ? '0' + myDate.getMinutes() : myDate.getMinutes();
      var sec = myDate.getSeconds() < 10 ? '0' + myDate.getSeconds() : myDate.getSeconds();
      var wk = myDate.getDay();
      var weeks = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
      var week = weeks[wk]
      this.time = myDate.getFullYear() + "年" + month + "月" + date + "日" + "   " + week + hou+ ":" + min+ ":" + sec;
      // console.log(this.time);
    }
  }
}
</script>

<style lang="scss" scoped>
  .header-box {
    // width: 1600px;
    height: 100px;
    margin: 0 auto;
    position: relative;
    color: #c58b48;
    font-size: 22px;
    overflow: hidden;
  }
  .floatL{
    float: left;
    font-size: 26px;
    height: 100px;
    width: 450px;
    text-align: left;
    line-height: 100px;
  }
  .floatR{
    float: right;
    display: block;
    height: 50px;
    line-height: 50px;
    margin-top: 20px;
    font-size: 26px;
  }
  .logo{
    // height: 100px;
    width: 400px;
    vertical-align: middle;
  }
</style>
