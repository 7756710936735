import Vue from 'vue'
import Router from 'vue-router'
import BaseLayout from '@/views/baseLayOut/index.vue'

Vue.use(Router)

export const constantRouterMap = [
  {
    path: '/',
    component: BaseLayout,
    redirect: '/home',
    name: 'MainMenu',
    children: [
      {
        path: '/home',
        name: 'MyHome',
        component: () => import('@/views/home/index.vue'),
        meta: { title: '首页' }
      },
      {
        path: '/listShow',
        name: 'ListShow',
        component: () => import('@/views/list/index.vue'),
        meta: { title: '演出列表' }
      },
      {
        path: '/showDetail',
        name: 'ShowDetail',
        component: () => import('@/views/showDetail/index.vue'),
        meta: { title: '商品详情' }
      },
      {
        path: '/seat',
        name: 'ChangeSeat',
        component: () => import('@/views/changeSeat/index.vue'),
        meta: { title: '影院选座' },
        props:true
      },            
      {
        path: '/payOrder',
        name: 'PayOrder',
        component: () => import('@/views/payOrder/index.vue'),
        meta: { title: '支付页面' },
        props:true
      }, {
        path: '/ticketCollection',
        name: 'TicketCollection',
        component: () => import('@/views/ticketCollection/index.vue'),
        meta: { title: '取票页面' },
        props:true
      }, 
      {
        path: '/takeTic',
        name: 'TakeTic',
        component: () => import('@/views/takeTic/index.vue'),
        meta: { title: '出票页面' },
        props:true
      }, 
      {
        path: '/offLine',
        name: 'OffLine',
        component: () => import('@/views/offLine/index.vue'),
        meta: { title: '断网页面' },
        props:true
      },       
    ]
  },
  
]

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default new Router({
  routes: constantRouterMap
})
