import router from './router'

// const whiteList = ['/login', '/test', '/meeting-detail'] // no redirect whitelist

router.afterEach(() => {
  window.scroll(0, 0)
  // if (to.path !== from.path) {
  //   document.title = store.state.user.customTitleMap[to.name] || to.meta.title || '演出票务'
  // }
})
